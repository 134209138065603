import { Component, inject, signal, OnDestroy } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { filter, map } from 'rxjs';

import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { AuthService } from '../../services/userservices/auth.service';

@Component({
  selector: 'app-loggedin',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIcon,
  ],
  templateUrl: './redirect.component.html',
  styleUrl: './redirect.component.scss',
})
export class RedirectComponent implements OnDestroy {
  auth = inject(AuthService);
  router = inject(Router);

  title = 'Login Status';

  dots = '';
  index = 0;

  constructor() {
    this.auth.completeLogin(window.location.href).subscribe();

    setInterval(() => {
      const dots = ['', '.', '..', '...', '....'];
      this.dots = dots[(this.index + 1) % dots.length];
      this.index++;
    }, 1000);
  }

  loggedinstatus = signal(false);
  subscription = this.auth
    .checkUser()
    .pipe(
      filter((user) => user !== undefined),
      map((user) => user !== null),
    )
    .subscribe((user) => {
      this.loggedinstatus.set(user);
    });

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
