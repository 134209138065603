import {
  HttpEvent,
  HttpRequest,
  HttpInterceptorFn,
  HttpResponse,
  HttpHandlerFn,
} from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { tap, shareReplay } from 'rxjs/operators';

// A simple cache structure to store responses
const cache = new Map<string, HttpResponse<any>>();

export const cacheInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
  // Clone request to remove 'x-skip-cache' header
  const cleanReq = req.clone({ headers: req.headers.delete('X-Skip-Cache') });
  const skipCache = req.headers.get('X-Skip-Cache');

  console.log('skipcache', skipCache, 'body', req.body);

  // Check if caching is requested via 'cacheType' in the body
  const shouldCache = req.body?.cacheType === true;
  // Generate a cache key using URL and a stringified version of the request body
  const cacheKey = `${req.url}_${JSON.stringify(req.body)}`;

  if (skipCache && skipCache === 'true') {
    // Fetch new data and update cache if needed
    return next(cleanReq).pipe(
      tap((event) => {
        if (event instanceof HttpResponse && shouldCache) {
          cache.set(cacheKey, event);
        }
      }),
    );
  }

  if (shouldCache) {
    const cachedResponse = cache.get(cacheKey);
    if (cachedResponse) {
      // Return cached response if available
      return of(cachedResponse);
    } else {
      // Otherwise, proceed with the request and cache the new response
      return next(cleanReq).pipe(
        tap((event) => {
          if (event instanceof HttpResponse) {
            cache.set(cacheKey, event);
          }
        }),
        shareReplay(1), // Ensure subsequent subscribers get the same cached response
      );
    }
  }

  // For requests not meant to be cached, simply forward them without caching
  return next(cleanReq);
};
