import { Component } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';


@Component({
  selector: 'app-close',
  standalone: true,
  imports: [MatIconModule,
    MatButtonModule
  ],
  templateUrl: './close.component.html',
  styleUrl: './close.component.scss'
})
export class CloseComponent {

}
