import { Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
  FormBuilder,
} from '@angular/forms';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
/**  [startAt]="formField.value | date : 'mediumDate'"  */

import { FilteredListComponent } from '../input/filtered-list/filtered-list.component';
import { FilteredListMultipleComponent } from '../input/filtered-list-multiple/filtered-list-multiple.component';
import { SimpleListMultipleComponent } from '../input/simple-list-multiple/simple-list-multiple.component';
import { frontendTypes } from '../../../assets/types';
import { FunctionsService } from '../../../services/functions/functions.service';
import { atLeastOne } from '../../../assets/shared/validators';

const nonEmptyValidator = Validators.required;

@Component({
  selector: 'app-input-form',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    FilteredListComponent,
    FilteredListMultipleComponent,
    SimpleListMultipleComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    TranslateModule,
  ],

  templateUrl: './input-form.component.html',
  styleUrl: './input-form.component.scss',
})
export class InputFormComponent {
  functions = inject(FunctionsService);
  translate = inject(TranslateService);
  formBuilder = new FormBuilder();

  private _contact: frontendTypes.Data | null = null;
  private _formInput: frontendTypes.formRow[] | null = null;
  private _parentForm: FormGroup = new FormGroup(
    {},
    { validators: atLeastOne(nonEmptyValidator) },
  );
  formGroupInitialized = false;
  initMessage = 'Initializing';
  @Input()
  set formInput(input: frontendTypes.formRow[] | null) {
    this._formInput = input;
    this.createOrUpdateControls();
  }

  @Input()
  set contact(input: frontendTypes.Data | null) {
    console.log('contact set in input-form: ' + JSON.stringify(input));
    this._contact = input;
    this.createOrUpdateControls();
  }

  get formInput(): frontendTypes.formRow[] | null {
    return this._formInput || null;
  }

  @Input()
  set parentForm(form: FormGroup) {
    this._parentForm = form;
    this.createOrUpdateControls();
  }

  get parentForm(): FormGroup {
    return this._parentForm;
  }

  private createOrUpdateControls() {
    console.log(
      'Initializing form... forminput:',
      JSON.stringify(this._formInput),
    );
    if (
      !this._parentForm ||
      !this._formInput ||
      this._formInput === undefined
    ) {
      console.warn('Parent form or form input not initialized.');
      this.initMessage = 'No form available';
      return;
    }

    // Clear existing controls
    Object.keys(this._parentForm.controls).forEach((controlKey) => {
      if (this._parentForm?.controls[controlKey]) {
        this._parentForm.removeControl(controlKey);
      }
    });

    // Add new controls from the input
    this._formInput.forEach((row) => {
      row.fields.forEach((field) => {
        if (field) {
          const validators = [];
          if (field.required) validators.push(Validators.required);
          if (
            this.functions.getDataField(field.dataFieldId)?.dataType === 'email'
          )
            validators.push(Validators.email);

          // Ensure control is not already added
          if (!this._parentForm!.contains(field.dataFieldId.toString())) {
            const initialValue =
              this._contact?.details.find((f) => f.fieldId == field.dataFieldId)
                ?.value || ''; // Default to empty string if no initial value is provided
            const control = this.formBuilder.control(initialValue, validators);
            this._parentForm!.addControl(field.dataFieldId.toString(), control);
          }
        }
      });
    });

    console.log('Form initialized.');
    this.formGroupInitialized = true;
  }
}
