import { Injectable, inject, signal, Signal, computed } from '@angular/core';
import { from, switchMap, catchError, timer } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { backendTypes, frontendTypes, httpTypes } from '../../assets/types';
import { HeadersService } from '../httpservices/headers.service';
import { typeGuards } from '../dataservices/typeguards/typeguards';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private backendUrl = environment.backendUrl;
  private http = inject(HttpClient);
  private headers = inject(HeadersService);
  private _config = signal<backendTypes.sqlConfig | null>(null);
  config = computed(() => {
    if (!this._config()) {
      this.updateConfig();
    }
    return this._config();
  });

  inputTypes: Signal<frontendTypes.inputType[]> = computed(() => {
    if (!this.config()) {
      return [
        {
          categoryId: '0',
          categoryName: 'undefined',
          inputTypeId: '0',
          dataType: 'default',
          inputTypeName: 'undefined',
        },
      ];
    }

    return this.config()!.inputTypes;
  });

  systemDataFields: Signal<frontendTypes.systemDataField[]> = computed(() => {
    if (!this.config()) {
      return [
        {
          id: '1',
          name: 'fieldId',
          description: 'Id of system dataField',
          label: 'ID',
          fieldTypeId: '1',
          dataType: 'string',
        },
      ];
    }

    console.log(this.config()!.systemDataFields);
    console.log(
      'systemdatafields in config: ' +
        JSON.stringify(this.config()!.systemDataFields),
    );
    return this.config()!.systemDataFields;
  });

  private _refreshRates: Signal<frontendTypes.refreshRates> = computed(() => {
    const config = this.config(); // Store the result of this.config()

    if (config) {
      return {
        refreshDefault: config.refreshRates?.RefreshDefault || 6000,
        refreshUser: config.refreshRates?.RefreshUser || 6000,
        refreshContacts: config.refreshRates?.RefreshContacts || 6000,
        refreshSettings: config.refreshRates?.RefreshSettings || 6000,
      } as frontendTypes.refreshRates;
    }

    return {
      refreshDefault: 6000,
      refreshUser: 6000,
      refreshContacts: 6000,
      refreshSettings: 6000,
    } as frontendTypes.refreshRates;
  });

  _refreshDefault = toSignal(timer(0, this._refreshRates().refreshDefault), {
    initialValue: 0,
  });
  _refreshUser = toSignal(timer(0, this._refreshRates().refreshUser), {
    initialValue: 0,
  });
  _refreshContacts = toSignal(timer(0, this._refreshRates().refreshContacts), {
    initialValue: 0,
  });
  _refreshSettings = toSignal(timer(0, this._refreshRates().refreshSettings), {
    initialValue: 0,
  });

  firerefreshDefault = signal(0);
  refreshDefault = computed(
    () => this._refreshDefault() + this.firerefreshDefault(),
  );

  firerefreshUser = signal(0);
  refreshUser = computed(() => this._refreshUser() + this.firerefreshUser());

  firerefreshContacts = signal(0);
  refreshContacts = computed(
    () => this._refreshContacts() + this.firerefreshContacts(),
  );

  firerefreshSettings = signal(0);
  refreshSettings = computed(
    () => this._refreshSettings() + this.firerefreshSettings(),
  );

  constructor() {
    console.log('Config Service started');
  }

  initialize(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.updateConfig()
        .then(() => {
          console.log('Config Service initialized');
          resolve(); // Resolve the promise after the config is fetched
        })
        .catch((error) => {
          console.error('Failed to initialize Config Service', error);
          reject(error); // Reject the promise if an error occurs
        });
    });
  }

  updateConfig(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.fetchData(
        {
          cacheType: true,
          request: 'GetApplicationConfig',
          requestType: 'stored-procedure',
          parameters: { ApplicationId: environment.applicationId },
        },
        true,
      ).subscribe({
        next: (config) => {
          console.log('Raw config:', config);

          if (config[0] && typeGuards.isSqlConfig(config[0])) {
            try {
              const parsedData = JSON.parse(JSON.stringify(config[0]));
              const formattedConfig = JSON.parse(parsedData.configJSON);
              console.log('config service: ' + JSON.stringify(formattedConfig));
              this._config.set(formattedConfig as backendTypes.sqlConfig);
            } catch (error) {
              console.error('Error parsing config: ', error);
            }

            resolve(); // Resolve the promise once the config has been successfully fetched
          } else {
            console.log('No config found or error occurred.');
          }
        },
        error: (err) => {
          console.error('Error fetching config', err);
          reject(err); // Reject the promise in case of an error
        },
      });
    });
  }

  fetchData(request: httpTypes.DbRequest, forceRefresh = true) {
    const headersObservable = from(this.headers.createHeaders());
    return headersObservable.pipe(
      switchMap((headers) => {
        let finalHeaders: HttpHeaders = headers;
        if (forceRefresh) {
          // Note: Ensure append is supported by your createHeaders implementation
          finalHeaders = finalHeaders.append('X-Skip-Cache', 'true');
        }
        // Proceed with the HTTP request now that headers are resolved
        return this.http.post<httpTypes.DbResponse>(this.backendUrl, request, {
          headers: finalHeaders,
        });
      }),
      catchError((error) => {
        // Handle or rethrow error as needed
        throw error;
      }),
    );
  }
}

export const appInitializerFactory = (configService: ConfigService) => {
  return () => configService.initialize();
};
