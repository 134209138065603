import { HTTP_INTERCEPTORS, HttpInterceptor, HttpRequest, HttpHandlerFn, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { cacheInterceptor } from './cache.interceptor'; // Your functional interceptor
import { HttpErrorInterceptor } from './error-interceptor';


// convert interceptor function into injectable class

@Injectable()
export class CacheInterceptorAdapter implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Adapt the `HttpHandler` to the expected `HttpHandlerFn` type
        const adaptedNext: HttpHandlerFn = (req: HttpRequest<any>) => next.handle(req);
        // Now, call your functional interceptor with the adapted handler
        return cacheInterceptor(req, adaptedNext);
    }
}

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptorAdapter, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
];
