import { frontendTypes } from '../types';

export const DatafieldColumns: frontendTypes.tableColumn[] = [
  {
    dataFieldId: '7',
  },
  {
    dataFieldId: '8',
  },
  {
    dataFieldId: '9',
  },

  {
    dataFieldId: '10',
  },
  {
    dataFieldId: '11',
  },
  {
    dataFieldId: '12',
  },
];

export const DATAFIELDS: frontendTypes.dataField[] = [
  {
    fieldId: '1',
    name: 'Name',
    description: 'General inputfield for Name of Item',
    fieldTypeId: '1',
    label: 'Name',
    dataType: 'string',
  },
  {
    fieldId: '2',
    name: 'FirstName',
    description: 'General inputfield for First Name',
    fieldTypeId: '1',
    label: 'First Name',
    dataType: 'string',
  },
  {
    fieldId: '3',
    name: 'Email',
    description: 'General inputfield for Email',
    fieldTypeId: '2',
    label: 'E-mail',
    dataType: 'email',
  },

  {
    fieldId: '4',
    name: 'PhoneNumber',
    description: 'General inputfield for Phone Number',
    fieldTypeId: '1',
    label: 'Phone Number',
    dataType: 'string',
  },
  {
    fieldId: '5',
    fieldTypeId: '1',
    name: 'OfficeNumber',
    label: 'Office Number',
    description: 'General inputfield for Office Number',
    dataType: 'string',
  },
  {
    fieldId: '6',
    fieldTypeId: '1',
    name: 'Notes',
    description: 'General inputfield for Notes',
    label: 'Notes',
    dataType: 'string',
  },
  {
    fieldId: '7',
    fieldTypeId: '1',
    name: 'fieldId',
    description: 'Id of dataField',
    label: 'ID',
    dataType: 'string',
  },
  {
    fieldId: '8',
    fieldTypeId: '1',
    name: 'fieldTypeId',
    description: 'Input Type of dataField',
    label: 'ID Field Type',
    dataType: 'string',
  },
  {
    fieldId: '9',
    fieldTypeId: '1',
    name: 'name',
    description: 'name of dataField',
    label: 'Name',
    dataType: 'string',
  },
  {
    fieldId: '10',
    fieldTypeId: '1',
    name: 'description',
    description: 'description of dataField',
    label: 'Description',
    dataType: 'string',
  },
  {
    fieldId: '11',
    fieldTypeId: '1',
    name: 'label',
    description: 'label of dataField',
    label: 'Label',
    dataType: 'string',
  },
  {
    fieldId: '12',
    fieldTypeId: '1',
    name: 'dataType',
    description: 'dataType of dataField',
    label: 'Data Type',
    dataType: 'string',
  },
];

export const inputFormContacts: frontendTypes.inputForm[] = [
  {
    formId: '1',
    formName: 'Default',
    createdAt: '20240925',
    inputCategoryId: '3',
    fields: [
      {
        order: 1,
        fields: [
          {
            dataFieldId: '1',
            fieldOrder: 1,
            required: true,
          },
          {
            dataFieldId: '2',
            fieldOrder: 2,
            required: true,
          },
        ],
      },
      {
        order: 2,
        fields: [
          {
            dataFieldId: '3',
            fieldOrder: 1,
            required: true,
          },
        ],
      },
      {
        order: 3,
        fields: [
          {
            dataFieldId: '4',
            fieldOrder: 1,
            required: false,
          },
          {
            dataFieldId: '5',
            fieldOrder: 2,
            required: false,
          },
        ],
      },
      {
        order: 4,
        fields: [
          {
            dataFieldId: '6',
            fieldOrder: 1,
            required: false,
          },
        ],
      },
    ],
  },
];
