import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../userservices/auth.service';

import { filter, take, map, Observable } from 'rxjs';

export const LoginGuard: CanActivateFn = (
  route,
  state,
): Observable<boolean | UrlTree> => {
  const auth = inject(AuthService);
  const router = inject(Router);

  const GuardResponse = auth.checkUser().pipe(
    filter((user) => user !== undefined),
    take(1),
    map((user) => {
      if (user !== null) {
        return router.createUrlTree(['/dashboard']);
      }
      console.log('no user');

      return true;
    }),
  );

  return GuardResponse;
};
