import { Component, inject, effect, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CheckOverflowDirective } from '../../directives/check-overflow.directive';

import { ContactColumns } from '../../assets/model/contact.model';
import { AddcontactComponent } from './addcontact/addcontact.component';
import { CloseComponent } from '../ui-components/buttons/close/close.component';
import { EditComponent } from '../ui-components/buttons/edit/edit.component';
import { ContactsService } from '../../services/dataservices/contacts/contacts.service';
import { ConfirmDialogComponent } from '../ui-components/confirm-dialog/confirm-dialog.component';
import { EditContactComponent } from './editcontact/editcontact.component';
import { UploadfileService } from '../../services/dataservices/fileservices/uploadfile.service';
import { FunctionsService } from '../../services/functions/functions.service';
import { frontendTypes } from '../../assets/types';

import { DynamicTableComponent } from '../ui-components/dynamic-table/dynamic-table.component';

@Component({
  selector: 'app-contacts',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    // MatTableModule,
    CommonModule,
    ConfirmDialogComponent,
    CloseComponent,
    EditComponent,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    CheckOverflowDirective,
    DynamicTableComponent,
  ],
  templateUrl: './contacts.component.html',
  styleUrl: './contacts.component.scss',
})
export class ContactsComponent {
  ContactColumns = ContactColumns;
  translate = inject(TranslateService);

  displayedColumns: string[] = ContactColumns.map(
    (col: frontendTypes.tableColumn) => col.dataFieldId,
  );
  dialog = inject(MatDialog);
  contactsService = inject(ContactsService);
  uploadFileService = inject(UploadfileService);
  functions = inject(FunctionsService);

  // dataSource = new MatTableDataSource<frontendTypes.Data>();
  contacts = this.contactsService.contacts;

  // constructor() {
  //   effect(() => {
  //     if (this.contacts() !== null && this.contacts() !== undefined) {
  //       this.dataSource.data = this.contacts() as frontendTypes.Data[];
  //     }
  //   });
  // }

  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue;
  // }
  removeRow = (id: string) => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        this.contactsService.DeleteContact(id);
      } else {
        console.log('contact delete canceled');
      }
    });
  };

  addContact = () => {
    const dialogRef = this.dialog.open(AddcontactComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  };

  editContact = (contactId: string) => {
    const contact = this.contacts()!.find((c) => c.id === contactId);
    const dialogRef = this.dialog.open(EditContactComponent, { data: contact });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  };

  // upload contacts

  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.uploadFileService.readFile(file);
    }
  }

  // ngOnInit(): void {
  //   // Custom filter function for the nested 'contactDetails'
  //   this.dataSource.filterPredicate = (
  //     data: frontendTypes.Data,
  //     filter: string,
  //   ) => {
  //     const filterValue = filter.trim().toLowerCase();

  //     // Check if any value in contactDetails contains the filter string
  //     return data.details.some((detail) =>
  //       detail.value.toLowerCase().includes(filterValue),
  //     );
  //   };
  // }
}
