import { Injectable, inject, signal, computed, effect } from '@angular/core';
import { catchError, of } from 'rxjs';

import { DataService } from '../dataservices/data.service';

import { environment } from '../../environments/environment';
import { ConfigService } from '../settings and config/config.service';

import { ErrorHandlingService } from '../errorhandling/error-handling.service';
import { typeGuards } from '../dataservices/typeguards/typeguards';
import { backendTypes, httpTypes } from '../../assets/types';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  config = inject(ConfigService);

  private dataService = inject(DataService);
  private errorHandlingService = inject(ErrorHandlingService);
  backendUrl = environment.backendUrl;

  constructor() {
    effect(() => {
      if (this.themePreference() === 'dark') {
        document.body.classList.add('dark-theme');
      } else {
        document.body.classList.remove('dark-theme');
      }
    });

    effect(() => {
      const trigger = this.config.refreshUser();

      this.getUser();
    });

    console.log('user service initialized');
  }
  private _user = signal<backendTypes.sqlUser | null>(null);

  user = computed(() => this._user());
  themePreference = computed(() => this._user()?.Theme || 'light');

  getUser() {
    this.dataService
      .fetchData({
        cacheType: false,
        request: 'GetUserData',
        requestType: 'stored-procedure',
        parameters: {},
      })
      .pipe(
        catchError((error) => {
          this.errorHandlingService.handleError(error.code, error.message);
          return of(null); // Return a fallback value or an observable that represents an empty user
        }),
      )
      .subscribe((user) => {
        console.log('user service: ' + JSON.stringify(user));
        if (user && typeGuards.isSqlUserArray(user)) {
          this._user.set(user[0] as backendTypes.sqlUser);
        } else {
          console.log('No user found or error occurred.');
          this.errorHandlingService.handleError(
            'No User',
            'No valid user found in database.',
          );
        }
      });
  }

  updateUser(updateFields: httpTypes.UpdateUserInput) {
    const request: httpTypes.DbRequest = {
      cacheType: false,
      request: 'UpdateUserTables',
      requestType: 'stored-procedure',
      parameters: updateFields,
    };

    this.dataService.fetchData(request).subscribe((update) => {
      console.log('update user response: ' + JSON.stringify(update));
      this.getUser();
    });
  }
}
