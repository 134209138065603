import { Injectable } from '@angular/core';

import * as Papa from 'papaparse'


@Injectable({
  providedIn: 'root'
})
export class UploadfileService {

  constructor() { }



  readFile(file: File) {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.processCSV(fileReader.result as string);
    };
    fileReader.readAsText(file);
  }
  
  processCSV(csvData: string) {
    
    Papa.parse(csvData, {
      complete: (result) => {
        console.log('Parsed: ', result.data);
        this.uploadData(result.data);
      }
    });
  }
  uploadData(data: any) {
    console.log("todo upload data");
  }

}
