import { Injectable, inject, effect, signal, computed } from '@angular/core';
import { catchError, of } from 'rxjs';

import { DataService } from '../../dataservices/data.service';

import { environment } from '../../../environments/environment';
import { ConfigService } from '../../settings and config/config.service';

import { ErrorHandlingService } from '../../errorhandling/error-handling.service';
import { typeGuards } from '../typeguards/typeguards';

import { frontendTypes } from '../../../assets/types';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  private dataService = inject(DataService);
  private config = inject(ConfigService);
  private errorHandlingService = inject(ErrorHandlingService);
  backendUrl = environment.backendUrl;

  cachedValue: any = null;

  constructor() {
    effect(() => {
      const trigger = this.config.refreshContacts();

      this.getContacts();
    });
  }
  private _contacts = signal<frontendTypes.Data[] | null>(null);

  contacts = computed(() => this._contacts());

  getContacts(forceRefresh = false) {
    this.dataService
      .fetchData(
        {
          cacheType: true,
          request: 'GetContacts',
          requestType: 'stored-procedure',
          parameters: { ApplicationId: environment.applicationId },
        },
        forceRefresh,
      )
      .pipe(
        catchError((error) => {
          this.errorHandlingService.handleError(error.code, error.message);
          return of(null); // Return a fallback value or an observable that represents an empty user
        }),
      )
      .subscribe((contacts) => {
        console.log('contacts raw: ' + JSON.stringify(contacts));
        console.log('contacts received: ' + JSON.stringify(contacts));
        if (contacts && typeGuards.isSqlContactArray(contacts)) {
          try {
            const contactsConverted = contacts.map((contact) => {
              return {
                id: contact.contactId,
                details: JSON.parse(contact.contactDetails),
              };
            });
            this._contacts.set(contactsConverted);
          } catch (e) {
            console.error('Failed to parse contact details:', e);
          }
        } else {
          console.log('No contacts found or error occurred.');
        }
      });
  }

  InsertContact(details: frontendTypes.detail[]) {
    this.dataService
      .fetchData({
        cacheType: false,
        request: 'InsertNewContact',
        requestType: 'stored-procedure',
        parameters: {
          details: details,
          ApplicationId: environment.applicationId,
        },
      })
      .pipe(
        catchError((error) => {
          this.errorHandlingService.handleError(error.code, error.message);
          return of(null); // Return a fallback value or an observable that represents an empty user
        }),
      )
      .subscribe(() => {
        this.getContacts(true);
      });
  }

  updateContact(details: frontendTypes.detail[], id: string) {
    this.dataService
      .fetchData({
        cacheType: false,
        request: 'UpdateContact',
        requestType: 'stored-procedure',
        parameters: {
          details: details,
          contactId: id,
          ApplicationId: environment.applicationId,
        },
      })
      .pipe(
        catchError((error) => {
          this.errorHandlingService.handleError(error.code, error.message);
          return of(null); // Return a fallback value or an observable that represents an empty user
        }),
      )
      .subscribe(() => {
        this.getContacts(true);
      });
  }

  DeleteContact(contactId: string) {
    this.dataService
      .fetchData({
        cacheType: false,
        request: 'DeleteContact',
        requestType: 'stored-procedure',
        parameters: {
          contactId: +contactId,
          ApplicationId: environment.applicationId,
        },
      })
      .pipe(
        catchError((error) => {
          this.errorHandlingService.handleError(error.code, error.message);
          return of(null); // Return a fallback value or an observable that represents an empty user
        }),
      )
      .subscribe(() => {
        this.getContacts(true);
      });
  }
}
