import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[checkOverflow]',
  standalone: true, // Make it standalone
  hostDirectives: [MatTooltip], // Import MatTooltip as part of the directive
})
export class CheckOverflowDirective {
  @Input() checkOverflow?: string; // Tooltip text

  constructor(
    private el: ElementRef,
    private tooltip: MatTooltip,
  ) {}

  // Listen to mouseenter event to dynamically show the tooltip
  @HostListener('mouseenter') onMouseEnter() {
    const element = this.el.nativeElement;

    // Check if the element is overflowing
    if (element.offsetWidth < element.scrollWidth) {
      // Enable tooltip only when overflow occurs
      this.tooltip.message = this.checkOverflow || element.textContent.trim();
      this.tooltip.show();
    } else {
      // Hide the tooltip if not overflowing
      this.tooltip.hide();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.tooltip.hide();
  }
}
