<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="headerContainer">
    <h2 mat-dialog-title>New Contact</h2>
 <!--   <button type="button" (click)="contactForm()">
      <mat-icon>settings</mat-icon>
    </button> -->
  </div>
  <mat-dialog-content>
    <app-input-form [formInput]="fields()"  [contact]="null"  [parentForm]="form" ></app-input-form>

    <div class="errorcontainer">
      @if(form.invalid){
      <span>fields marked with * are required</span>
      } @else {<span class="spacer"> </span>}
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-button
      [mat-dialog-close]="false"
      (click)="form.removeControl('dataForm')"
    >
      Cancel
    </button>
    <button
      mat-button
      type="submit"
      [disabled]="!form.valid"
      [mat-dialog-close]="true"
    >
      Save
    </button>
  </mat-dialog-actions>
</form>
