import { frontendTypes } from './../types';

export const ContactColumns: frontendTypes.tableColumn[] = [
  {
    dataFieldId: '1',
  },
  {
    dataFieldId: '2',
  },
  {
    dataFieldId: '3',
  },

  {
    dataFieldId: '4',
  },
  {
    dataFieldId: '5',
  },
  {
    dataFieldId: '6',
  },
];

export const inputFormContacts: frontendTypes.inputForm[] = [
  {
    formId: '1',
    formName: 'Default',
    createdAt: '20240925',
    inputCategoryId: '3',
    fields: [
      {
        order: 1,
        fields: [
          {
            dataFieldId: '1',
            fieldOrder: 1,
            required: true,
          },
          {
            dataFieldId: '2',
            fieldOrder: 2,
            required: true,
          },
        ],
      },
      {
        order: 2,
        fields: [
          {
            dataFieldId: '3',
            fieldOrder: 1,
            required: true,
          },
        ],
      },
      {
        order: 3,
        fields: [
          {
            dataFieldId: '4',
            fieldOrder: 1,
            required: false,
          },
          {
            dataFieldId: '5',
            fieldOrder: 2,
            required: false,
          },
        ],
      },
      {
        order: 4,
        fields: [
          {
            dataFieldId: '6',
            fieldOrder: 1,
            required: false,
          },
        ],
      },
    ],
  },
];
