import { Component, inject, computed, signal, Inject } from '@angular/core';

import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  MatDialogModule,
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { InputFormComponent } from '../../ui-components/input-form/input-form.component';
import { inputFormContacts } from '../../../assets/model/contact.model';

import { ContactsService } from '../../../services/dataservices/contacts/contacts.service';
import { SettingsService } from '../../../services/settings and config/settings.service';
import { ConfigService } from '../../../services/settings and config/config.service';
import { frontendTypes } from '../../../assets/types';

@Component({
  selector: 'app-editcontact',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    InputFormComponent,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './editcontact.component.html',
  styleUrl: './editcontact.component.scss',
})
export class EditContactComponent {
  form = new FormGroup({});

  contactsService = inject(ContactsService);
  settingsService = inject(SettingsService);
  configService = inject(ConfigService);
  dialog = inject(MatDialog);

  formInput = signal(inputFormContacts);

  //this.settingsService.contactForms;
  fields = computed(() =>
    this.formInput() && this.formInput()[0] != null
      ? this.formInput()[0]!.fields
      : null,
  );

  constructor(
    public dialogRef: MatDialogRef<EditContactComponent>,
    @Inject(MAT_DIALOG_DATA) public data: frontendTypes.Data,
  ) {
    console.log('data in edit contact: ' + JSON.stringify(this.data));
  }

  onSubmit() {
    console.log('edited fields: ' + JSON.stringify(this.form.getRawValue()));

    let contactDetails: [frontendTypes.detail] | undefined = undefined;

    for (const [key, value] of Object.entries(this.form.getRawValue())) {
      if (contactDetails == undefined) {
        contactDetails = [{ fieldId: parseInt(key).toString(), value: value }];
      } else {
        contactDetails.push({
          fieldId: parseInt(key).toString(),
          value: value,
        });
      }
    }
    this.contactsService.updateContact(contactDetails!, this.data.id);
  }
}
