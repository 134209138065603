import { ApplicationConfig, importProvidersFrom, APP_INITIALIZER } from '@angular/core';
import { provideRouter } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import { httpInterceptorProviders } from '../services/httpservices/interceptors/index';
import { UserService } from '../services/userservices/user.service';
import { AuthService } from '../services/userservices/auth.service';
import { SettingsService } from '../services/settings and config/settings.service';
import { ConfigService, appInitializerFactory } from '../services/settings and config/config.service';
import { environment } from '../environments/environment';
import { LanguageService } from '../services/language/language.service';
import { ErrorHandlingService } from '../services/errorhandling/error-handling.service';

const firebaseConfig = environment.firebaseConfig;

export const appConfig: ApplicationConfig = {
  providers: [
    provideFirebaseApp(() => initializeApp(firebaseConfig)),
    provideAuth(() => getAuth()),
    httpInterceptorProviders,
    AuthService,
    { provide: APP_INITIALIZER, useFactory: appInitializerFactory, deps: [ConfigService], multi: true },
    SettingsService,
   // ConfigService,
    UserService,
    LanguageService,
    ErrorHandlingService,
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideRouter(routes),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideAnimationsAsync(),
   

    importProvidersFrom([
      

      TranslateModule.forRoot({
        defaultLanguage: 'en',
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ]),
  ],
};
