import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { switchMap, catchError, from, of } from 'rxjs';

import { HeadersService } from '../httpservices/headers.service';
import { environment } from '../../../src/environments/environment';
import { ConfigService } from '../settings and config/config.service';
import { httpTypes } from '../../assets/types';
import { ErrorHandlingService } from '../errorhandling/error-handling.service';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private http = inject(HttpClient);
  private errorHandlingService = inject(ErrorHandlingService);
  config = inject(ConfigService);
  private headers = inject(HeadersService);
  private backendUrl = environment.backendUrl;

  fetchData(request: httpTypes.DbRequest, forceRefresh = false) {
    const headersObservable = from(this.headers.createHeaders());
    return headersObservable.pipe(
      switchMap((headers) => {
        let finalHeaders: HttpHeaders = headers;
        if (forceRefresh) {
          // Note: Ensure append is supported by your createHeaders implementation
          finalHeaders = finalHeaders.append('X-Skip-Cache', 'true');
        }
        // Proceed with the HTTP request now that headers are resolved
        return this.http.post<httpTypes.DbResponse>(this.backendUrl, request, {
          headers: finalHeaders,
        });
      }),
      catchError((error) => {
        // Handle or rethrow error as needed
        throw error;
      }),
    );
  }

  DeleteDataField(Id: string) {
    this.fetchData({
      cacheType: false,
      request: 'DeleteDataField',
      requestType: 'stored-procedure',
      parameters: {
        dataFieldId: +Id,
        ApplicationId: environment.applicationId,
      },
    })
      .pipe(
        catchError((error) => {
          this.errorHandlingService.handleError(error.code, error.message);
          return of(null); // Return a fallback value or an observable that represents an empty user
        }),
      )
      .subscribe(() => {
        this.config.firerefreshSettings.set(0);
      });
  }
}
