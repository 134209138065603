<BR />
<mat-form-field>
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="" #input />
</mat-form-field>

<!-- <input type="file" (change)="onFileChange($event)" accept=".csv" /> -->
<!-- style="display: table" -->

<table
  mat-table
  [dataSource]="dataSource"
  class="mat-elevation-z8"
  style="display: table"
>
  <ng-container
    *ngIf="tableSettings().removeButton"
    matColumnDef="deleteColumn"
  >
    <th mat-header-cell *matHeaderCellDef>
      <button
        *ngIf="tableSettings().addButton"
        mat-icon-button
        (click)="addRow()"
      >
        <mat-icon>add</mat-icon>
      </button>
    </th>
    <td mat-cell *matCellDef="let element">
      <app-close (click)="removeRow(element.id)"></app-close>
    </td>
  </ng-container>

  <ng-container
    *ngIf="
      tableSettings().editButton ||
      (tableSettings().addButton && !tableSettings().removeButton)
    "
    matColumnDef="editColumn"
  >
    <th mat-header-cell *matHeaderCellDef>
      <button
        *ngIf="tableSettings().addButton && !tableSettings().removeButton"
        mat-icon-button
        (click)="addRow()"
      >
        <mat-icon>add</mat-icon>
      </button>
    </th>

    <td mat-cell *matCellDef="let element">
      <app-edit (click)="editRow(element.id)"></app-edit>
    </td>
  </ng-container>

  <ng-container
    [matColumnDef]="col"
    *ngFor="let col of tableSettings().dataColumns"
  >
    <ng-container
      *ngIf="functions.getDataField(col, _systemTable() || false) as dataField"
    >
      <th
        mat-header-cell
        mat-header-cell
        *matHeaderCellDef
        [ngSwitch]="col"
        class="cell-content-ellipsis"
      >
        <span *ngSwitchDefault>{{ dataField.label }}</span>
      </th>
      <td
        mat-cell
        mat-cell
        *matCellDef="let element"
        [ngSwitch]="dataField.dataType"
        class="cell-content-ellipsis"
        checkOverflow="{{ functions.getDetailValue(element.details, col) }}"
      >
        <ng-container
          *ngIf="functions.getDetailValue(element.details, col) as detailValue"
        >
          <span *ngSwitchCase="'date'">{{
            detailValue | date: "mediumDate"
          }}</span>
          <span *ngSwitchCase="'text'">{{ detailValue }}</span>
          <span *ngSwitchDefault>{{ detailValue }}</span>
        </ng-container>
      </td>
    </ng-container>
  </ng-container>

  <tr
    mat-row
    mat-header-row
    *matHeaderRowDef="tableSettings().displayedColumns"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: tableSettings().displayedColumns"
    [class.hidden]="row.collapsed"
    class="table-row"
  ></tr>

  <!--  Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">
      No data matching the filter "{{ input.value }}"
    </td>
  </tr>
</table>

<p>systemTable: {{ _systemTable() | json }}</p>
