import { Injectable, inject, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {
  Auth,
  User,
  user,
  getAuth,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from '@angular/fire/auth';
import {
  Observable,
  from,
  EMPTY,
  Subscription,
  filter,
  BehaviorSubject,
} from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private firebaseAuth = inject(Auth);
  private auth = getAuth();
  router = inject(Router);
  private currentUrl = '';

  checkUser(): Observable<User | null | undefined> {
    return this.user$;
  }

  private _user$ = user(this.firebaseAuth);
  user$ = new BehaviorSubject<User | null | undefined>(undefined);
  userSubscription: Subscription;

  constructor() {
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd,
        ),
      )
      .subscribe((event: NavigationEnd) => {
        this.currentUrl = event.url;
      });

    this.userSubscription = this._user$.subscribe((aUser: User | null) => {
      if (aUser) {
        console.log('User logged in:', aUser);
        this.user$.next(aUser);
        // Resolve the Promise to get the token
        aUser.getIdTokenResult().then((tokenResult) => {
          const token = tokenResult.token;
          // Now that you have the token string, set it
        });
      } else {
        // Optionally, clear the access token if there's no user logged in

        this.user$.next(null);
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => {
            this.router.navigate([this.currentUrl]);
          });
      }
    });
  }

  actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: environment.firebaseRedirectUri,
    // This must be true.
    handleCodeInApp: true,

    // dynamicLinkDomain: 'example.page.link'
  };

  login(email: string): void {
    const promise = sendSignInLinkToEmail(
      this.firebaseAuth,
      email,
      this.actionCodeSettings,
    )
      .then(() => {
        window.localStorage.setItem('emailForSignIn', email);
        this.router.navigate(['checkmail']);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage, errorCode);
      });
    from(promise).subscribe(() => {});
  }

  completeLogin(href: string): Observable<void> {
    if (isSignInWithEmailLink(this.auth, href)) {
      console.log('email link');
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }

      return from(
        signInWithEmailLink(this.auth, email || '', href)
          .then(() => {
            window.localStorage.removeItem('emailForSignIn');
            this.router.navigate(['dashboard']);
          })
          .catch((error) => {
            console.log(error);
            this.router.navigate(['login']);
          }),
      );
    }
    console.log('no email link');
    this.router.navigate(['login']).catch((err) => {
      console.error('Navigation error:', err);
    });
    return from(EMPTY);
  }

  logOut(): void {
    this.firebaseAuth.signOut();
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }
}
