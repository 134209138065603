import { Component, inject, computed } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CheckOverflowDirective } from '../../../directives/check-overflow.directive';
import { DataService } from '../../../services/dataservices/data.service';
import { ConfigService } from '../../../services/settings and config/config.service';

import { DatafieldColumns } from '../../../assets/model/data.model';
import { AdddatafieldComponent } from './adddatafield/adddatafield.component';
import { CloseComponent } from '../../ui-components/buttons/close/close.component';
import { EditComponent } from '../../ui-components/buttons/edit/edit.component';
import { SettingsService } from '../../../services/settings and config/settings.service';
import { ConfirmDialogComponent } from '../../ui-components/confirm-dialog/confirm-dialog.component';
import { EditdatafieldComponent } from './editdatafield/editdatafield.component';
import { UploadfileService } from '../../../services/dataservices/fileservices/uploadfile.service';
import { FunctionsService } from '../../../services/functions/functions.service';
import { frontendTypes } from '../../../assets/types';

import { DynamicTableComponent } from '../../ui-components/dynamic-table/dynamic-table.component';

@Component({
  selector: 'app-datafields',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,

    CommonModule,
    ConfirmDialogComponent,
    CloseComponent,
    EditComponent,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    CheckOverflowDirective,
    DynamicTableComponent,
  ],
  templateUrl: './datafields.component.html',
  styleUrl: './datafields.component.scss',
})
export class DatafieldsComponent {
  TableColumns = DatafieldColumns;
  translate = inject(TranslateService);
  dataService = inject(DataService);
  config = inject(ConfigService);

  displayedColumns: string[] = this.config
    .systemDataFields()
    .map((col: frontendTypes.systemDataField) => col.id);

  dialog = inject(MatDialog);
  settingsService = inject(SettingsService);
  uploadFileService = inject(UploadfileService);
  functions = inject(FunctionsService);

  tableData = computed(() =>
    this.settingsService
      .dataFields()
      .map((datafield: frontendTypes.dataField) => ({
        id: datafield.fieldId,
        details: [
          {
            fieldId: '1',
            value: datafield.fieldId,
          },
          {
            fieldId: '2',
            value: datafield.fieldTypeId,
          },
          {
            fieldId: '3',
            value: datafield.name,
          },
          {
            fieldId: '4',
            value: datafield.description,
          },
          {
            fieldId: '5',
            value: datafield.label,
          },
          {
            fieldId: '6',
            value: datafield.dataType,
          },
        ],
      })),
  );

  removeRow = (id: string) => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        this.dataService.DeleteDataField(id);
      } else {
        console.log('delete datafield canceled');
      }
    });

    console.log('datafield with id: ' + id + ' removed');
  };

  addRow = () => {
    console.log('row added');
    // const dialogRef = this.dialog.open(AdddatafieldComponent);

    // dialogRef.afterClosed().subscribe((result) => {
    //   console.log(`Dialog result: ${result}`);
    // });
  };

  editRow = (id: string) => {
    console.log('datafield with id: ' + id + ' edited');
    // const contact = this.contacts()!.find((c) => c.id === contactId);
    // const dialogRef = this.dialog.open(EditdatafieldComponent, {
    //   data: contact,
    // });

    // dialogRef.afterClosed().subscribe((result) => {
    //   console.log(`Dialog result: ${result}`);
    // });
  };

  // upload contacts

  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.uploadFileService.readFile(file);
    }
  }
}
