import {
  Injectable,
  inject,
  signal,
  computed,
  Signal,
  effect,
} from '@angular/core';

import { DataService } from '../dataservices/data.service';
import { inputFormContacts } from '../../assets/model/contact.model';
import { DATAFIELDS } from '../../assets/model/data.model';

import { environment } from '../../environments/environment';
import { ConfigService } from './config.service';
import { httpTypes, backendTypes, frontendTypes } from '../../assets/types';
import { typeGuards } from '../dataservices/typeguards/typeguards';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  config = inject(ConfigService);
  dataService = inject(DataService);

  backendUrl = environment.backendUrl;
  defaultInputFormContacts = inputFormContacts;
  defaultDataFields = DATAFIELDS;

  constructor() {
    console.log('settings service started');

    effect(() => {
      const trigger = this.config.refreshSettings();

      this.updateSettings();
    });
  }

  updateSettings() {
    this.dataService
      .fetchData(
        {
          cacheType: false,
          request: 'GetApplicationSettings',
          requestType: 'stored-procedure',
          parameters: { ApplicationId: environment.applicationId },
        },
        true,
      )
      .subscribe((settings) => {
        const parsedData = JSON.parse(JSON.stringify(settings));
        const formattedSettings = JSON.parse(parsedData.settingsJSON);
        console.log('settings service: ' + JSON.stringify(formattedSettings));
        this._settings.set(formattedSettings as frontendTypes.settings);
      });
  }

  private _settings = signal<frontendTypes.settings | null>(null);

  settings = computed(() => this._settings());

  dataFields: Signal<frontendTypes.dataField[]> = computed(() => {
    if (!this._settings() || !this._settings()?.dataFields) {
      return this.defaultDataFields;
    }
    return this._settings()!.dataFields;
  });

  inputForms: Signal<frontendTypes.inputForms> = computed(() => {
    if (!this._settings()) {
      return {
        contactForms: null,
        clientForms: null,
        projectForms: null,
        timeRegForms: null,
      };
    }

    const newInputForms =
      this._settings()!.inputForms !== undefined
        ? this._settings()!.inputForms.map((inputForm) => ({
            ...inputForm,
            fields: null,

            //this.convertFormFormat(inputForm.fields),
          }))
        : [];

    return {
      contactForms: newInputForms.filter(
        (inputForm) => inputForm.inputCategoryId == '3', // 3 is the contact input category Id in DataBase
      ),
      clientForms: newInputForms.filter(
        (inputForm) => inputForm.inputCategoryId == '0', // 0 is not yet assigned
      ),
      projectForms: newInputForms.filter(
        (inputForm) => inputForm.inputCategoryId == '0', // 0 is not yet assigned
      ),
      timeRegForms: newInputForms.filter(
        (inputForm) => inputForm.inputCategoryId == '0', // 0 is not yet assigned
      ),
    };
  });

  contactForms = computed(() => {
    console.log('contactForms: ' + this.inputForms().contactForms);
    if (this.inputForms().contactForms == null) {
      return this.defaultInputFormContacts;
    } // set contact form to the default if not available
    return this.inputForms().contactForms;
  });

  saveNewForm(formInput: frontendTypes.formRow[], categoryId: number) {
    const request: httpTypes.DbRequest = {
      cacheType: false,
      request: 'StoreNewForm',
      requestType: 'stored-procedure',
      parameters: {
        ApplicationId: environment.applicationId,
        InputCategoryId: categoryId,
        RowData: formInput,
        FormName: 'formdd' + new Date().getTime().toString(),
      },
    };

    this.dataService.fetchData(request).subscribe((response) => {
      console.log('newForm response: ' + JSON.stringify(response));
      this.updateSettings();
    });
  }

  convertFormFormat(
    fields: backendTypes.sqlFormField[],
  ): frontendTypes.formRow[] | null {
    let newFormFields: frontendTypes.formRow[] | null | undefined;

    fields.forEach((field) => {
      if (newFormFields === undefined) {
        newFormFields = [
          {
            order: field.rowOrder,
            fields: [
              {
                dataFieldId: field.dataFieldId,
                required: field.required,
                fieldOrder: field.fieldOrder,
              },
            ],
          },
        ];
      } else {
        const rowIndex = newFormFields!.findIndex((newField) => {
          return newField.order === field.rowOrder;
        });

        rowIndex !== -1
          ? newFormFields![rowIndex].fields.push({
              dataFieldId: field.dataFieldId,
              required: field.required,
              fieldOrder: field.fieldOrder,
            })
          : newFormFields!.push({
              order: field.rowOrder,
              fields: [
                {
                  dataFieldId: field.dataFieldId,
                  required: field.required,
                  fieldOrder: field.fieldOrder,
                },
              ],
            });
      }
    });

    newFormFields!
      ? newFormFields.sort((a, b) => a.order - b.order)
      : (newFormFields = null);
    newFormFields!
      ? newFormFields.forEach((row) =>
          row.fields.sort((a, b) => a!.fieldOrder - b!.fieldOrder),
        )
      : (newFormFields = null);

    return newFormFields;
  }
}
