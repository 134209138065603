import {
  AbstractControl,
  ValidatorFn,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';

export function atLeastOne(
  validator: ValidatorFn,
  controls: string[] | null = null
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control as FormGroup;

    if (!formGroup.controls) {
      return null; // or throw an error if it should not be used on non-FormGroup controls
    }

    if (!controls) {
      controls = Object.keys(formGroup.controls);
    }

    const hasAtLeastOne = controls.some(
      (k) => !validator(formGroup.controls[k])
    );

    return hasAtLeastOne ? null : { atLeastOne: true };
  };
}
