import { backendTypes } from '../../../assets/types';
import { NavigationEnd } from '@angular/router';

export const typeGuards = {
  isSqlUserArray,
  isSqlContactArray,
  isSqlSettings,
  isSqlConfig,
  isNavigationEnd,
};

function isArray(response: any): response is any[] {
  return Array.isArray(response);
}

function isSqlUserArray(response: any): response is backendTypes.sqlUser[] {
  return isArray(response) && response.length > 0 && 'Alias' in response[0];
}

function isSqlContactArray(
  response: any,
): response is backendTypes.sqlContact[] {
  return (
    isArray(response) && response.length > 0 && 'contactDetails' in response[0]
  );
}

function isSqlSettings(response: any): response is backendTypes.sqlSettings {
  return !isArray(response) && 'settingsJSON' in response;
}

function isSqlConfig(response: any): response is backendTypes.sqlConfig {
  return !isArray(response) && 'configJSON' in response;
}

function isNavigationEnd(event: any): event is NavigationEnd {
  return event instanceof NavigationEnd;
}
