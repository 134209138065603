import { InputFormEditorComponent } from './../../ui-components/input-form editor/input-form-editor.component';

import { Component, inject, computed, signal } from '@angular/core';

import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { InputFormComponent } from '../../ui-components/input-form/input-form.component';
import { inputFormContacts } from '../../../assets/model/contact.model';

import { ContactsService } from '../../../services/dataservices/contacts/contacts.service';
import { SettingsService } from '../../../services/settings and config/settings.service';
import { ConfigService } from '../../../services/settings and config/config.service';
import { frontendTypes } from '../../../assets/types';

@Component({
  selector: 'app-addcontact',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    InputFormComponent,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
  ],
  templateUrl: './addcontact.component.html',
  styleUrl: './addcontact.component.scss',
})
export class AddcontactComponent {
  form = new FormGroup({});

  contactsService = inject(ContactsService);
  settingsService = inject(SettingsService);
  configService = inject(ConfigService);
  dialog = inject(MatDialog);

  formInput = signal(inputFormContacts);

  //this.settingsService.contactForms;
  fields = computed(() =>
    this.formInput() && this.formInput()[0] != null
      ? this.formInput()[0]!.fields
      : null,
  );

  onSubmit() {
    console.log(this.form.getRawValue());

    let contactDetails: [frontendTypes.detail] | undefined = undefined;

    for (const [key, value] of Object.entries(this.form.getRawValue())) {
      if (contactDetails == undefined) {
        contactDetails = [{ fieldId: parseInt(key).toString(), value: value }];
      } else {
        contactDetails.push({
          fieldId: parseInt(key).toString(),
          value: value,
        });
      }
    }
    this.contactsService.InsertContact(contactDetails!);
  }

  contactForm() {
    const dialogRef = this.dialog.open(InputFormEditorComponent, {
      data: {
        formInput: this.fields(),
        inputTypes: this.configService.inputTypes(),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result:` + JSON.stringify(result));
      if (result) {
        this.settingsService.saveNewForm(result, 3); // 3 = contacts
      }
    });
  }
}
