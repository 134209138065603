import { Injectable, inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ErrorHandlingService } from '../../errorhandling/error-handling.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  errorHandlingService = inject(ErrorHandlingService);
  constructor() {
    console.log('error interceptor constructed');
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((response) => {
        if (response instanceof HttpResponse) {
          if (response.body.Status == 'failed') {
            this.errorHandlingService.handleError(
              response.body.error,
              response.body.errorMessage,
            );
            console.log('app-error' + JSON.stringify(response.body));
          }
        }
      }),
      catchError((error: any) => {
        console.log('error interceptor', error);
        if (error instanceof HttpErrorResponse) {
          //  Call the error handling service to handle the HTTP error
          this.errorHandlingService.handleHttpError(error);
        }
        //   Re-throw the error to propagate it to the caller
        return throwError(() => new HttpErrorResponse(error));
      }),
    );
  }
}
