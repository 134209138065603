import { Injectable, inject, computed, effect } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../userservices/user.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  translateService = inject(TranslateService);
  userService = inject(UserService);

  supportedLanguages = [
    { code: 'en', name: 'English', flag: "assets/flags/gb.png" },
    { code: 'nl', name: 'Dutch', flag: "assets/flags/nl.png" },
    // add more languages here
  ];

  browserLang = this.translateService.getBrowserLang();
  defaultLang = this.supportedLanguages.some(lang => lang.code === this.browserLang) ? this.browserLang! : 'en';
  selectedLanguage = computed(() => {

    const user = this.userService.user();
    console.log("user in Language Service (using userService: " + JSON.stringify(user))
    return user !== null && user.Language !== null ? user.Language : this.defaultLang;
  });

  constructor() {
    effect(() => {
      const lang = this.selectedLanguage();
      if (lang) {
        this.initializeLanguage();
      }
    });
    
    this.initializeLanguage();
  }

  initializeLanguage() {
    const selectedLang = this.selectedLanguage();
    console.log("browser lang is " + (this.browserLang ? this.browserLang : "undefined"));
    console.log("defaultLang  is " + (this.defaultLang ? this.defaultLang : "undefined"));
    console.log("userService Lang  is " + (selectedLang ? selectedLang : "undefined"));
    
    if (selectedLang) {
      this.translateService.use(selectedLang);
    } else {
      console.error('Selected language is undefined.');
    }
  }

  changeLanguage = (language: string) => {
    console.log("Changing language to: " + language);
    
    // Check if userService and updateUser are available
    if (this.userService && typeof this.userService.updateUser === 'function') {
      console.log("Calling updateUser to change language...");
      this.userService.updateUser({ "Language": language });
      console.log("Language change triggered in UserService.");
    } else {
      if (!this.userService) {
        console.error('userService is undefined.');
      } else {
        console.error('updateUser method is undefined on userService.');
      }
    }
  }
}
