<div class="editFormContainer">
  <h2 mat-dialog-title>Edit Contact Form</h2>
  <mat-dialog-content class="mat-typography">
    <div class="header">
      <label class="label">Row</label>
      <button mat-icon-button (click)="addRow(0)">
        <mat-icon>add</mat-icon>
      </button>
      <label class="label">New Data Field</label>
      <button mat-icon-button (click)="createDataField()">
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <ng-container *ngIf="newForm">
      @for(row of newForm; track rowIndex; let rowIndex = $index) {
      <div class="formfieldRow">
        @for(field of row.fields; track fieldIndex; let fieldIndex = $index) {
        <ng-container *ngIf="field !== null">
          <mat-form-field appearance="fill" [(ngModel)]="field.dataFieldId">
            <mat-label>Select Data Field</mat-label>
            <mat-select>
              @for(dataField of settingsService.dataFields(); track dataField; )
              {
              <mat-option [value]="dataField.fieldId">{{
                dataField.name
              }}</mat-option>
              }
            </mat-select>
            <mat-checkbox class="example-margin" [(ngModel)]="field.required"
              >required</mat-checkbox
            >
            <button
              mat-icon-button
              matSuffix
              (click)="removeField(rowIndex, fieldIndex)"
            >
              <mat-icon matSuffix>remove</mat-icon>
            </button>
          </mat-form-field>
        </ng-container>

        }

        <label class="label">Contact Field</label>
        <button mat-icon-button (click)="addField(rowIndex)">
          <mat-icon>add</mat-icon>
        </button>
        <label class="label">Row</label>
        <button mat-icon-button (click)="addRow(rowIndex + 1)">
          <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button (click)="removeRow(rowIndex)">
          <mat-icon>remove</mat-icon>
        </button>
      </div>
      }
    </ng-container>

    <mat-dialog-actions align="end">
      <button mat-button [mat-dialog-close]="null">Cancel</button>
      <button mat-button [mat-dialog-close]="newForm" cdkFocusInitial>
        Save
      </button>
    </mat-dialog-actions>
  </mat-dialog-content>
</div>
