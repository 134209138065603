import { Injectable, inject, signal, computed } from '@angular/core';

import { Router, NavigationEnd } from '@angular/router';

import { filter } from 'rxjs';

import { frontendTypes } from '../../assets/types';
import { SettingsService } from '../settings and config/settings.service';
import { typeGuards } from '../dataservices/typeguards/typeguards';
import { ConfigService } from '../settings and config/config.service';

@Injectable({
  providedIn: 'root',
})
export class FunctionsService {
  settingsService = inject(SettingsService);
  config = inject(ConfigService);
  router = inject(Router);
  _currentUrl = signal('');
  routerSubscription = this.router.events
    .pipe(filter((event) => typeGuards.isNavigationEnd(event)))
    .subscribe((event) => {
      console.log(event);
      this._currentUrl.set((event as NavigationEnd).urlAfterRedirects);
    });

  currentUrl = computed(() => this._currentUrl);

  getDetailValue(details: frontendTypes.detail[], fieldId: string): any {
    // returns the value of the contact detail

    const detail = details.find((d) => d.fieldId === fieldId);
    return detail ? detail.value : '';
  }

  getDataField(
    id: string,
    systemDataField = false,
  ): frontendTypes.dataField | frontendTypes.systemDataField {
    // returns the dataField based on the id

    return systemDataField
      ? (this.config.systemDataFields().find((field) => field.id == id) ?? {
          id: id,
          name: 'Invalid data field',
          description: 'Invalid data field',
          fieldTypeId: '1',
          label: 'Invalid data field',
          dataType: 'string',
        })
      : (this.settingsService
          .dataFields()
          .find((field) => field.fieldId == id) ?? {
          fieldId: id,
          name: 'Invalid data field',
          description: 'Invalid data field',
          fieldTypeId: '1',
          label: 'Invalid data field',
          dataType: 'string',
        });
  }
}
