import { Component } from '@angular/core';

import { MatDialogModule } from '@angular/material/dialog';
/** import {CancelComponent} from '../buttons/cancel/cancel.component' */

@Component({
  selector: 'app-confirm-dialogue',
  standalone: true,
  imports: [MatDialogModule],
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss'
})
export class ConfirmDialogComponent {

}
