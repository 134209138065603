import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  OnInit,
  Signal,
  inject,
  Inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  MatDialog,
  MatDialogModule,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

/**  [startAt]="formField.value | date : 'mediumDate'"  */

import { FilteredListComponent } from '../input/filtered-list/filtered-list.component';
import { FilteredListMultipleComponent } from '../input/filtered-list-multiple/filtered-list-multiple.component';
import { SimpleListMultipleComponent } from '../input/simple-list-multiple/simple-list-multiple.component';
import { CreateDataFieldComponent } from './create-datafield/create-datafield.component';
import { frontendTypes } from '../../../assets/types';
import { SettingsService } from '../../../services/settings and config/settings.service';

@Component({
  selector: 'app-input-form-editor',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    CommonModule,
    FilteredListComponent,
    FilteredListMultipleComponent,
    SimpleListMultipleComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatIconModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
  ],

  templateUrl: './input-form-editor.component.html',
  styleUrl: './input-form-editor.component.scss',
})
export class InputFormEditorComponent {
  /** Injected services */
  dialog = inject(MatDialog);
  settingsService = inject(SettingsService);

  /** input / output */

  newForm: frontendTypes.formRow[];
  inputTypes: frontendTypes.inputType[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      formInput: frontendTypes.formRow[];
      inputTypes: frontendTypes.inputType[];
    },
  ) {
    this.newForm = this.data.formInput;
    console.log('newForm in editor', this.newForm);
    this.inputTypes = this.data.inputTypes;
  }

  addRow(index: number) {
    console.log('addRow');
    if (this.newForm !== null) {
      this.newForm!.splice(index, 0, { order: index, fields: [] });
    } else {
      this.newForm = [{ order: index, fields: [] }];
    }
  }
  removeRow(index: number) {
    console.log('removeRow');
    if (this.newForm !== null) {
      this.newForm!.splice(index, 1);
      this.newForm!.forEach((row, index) => {
        row ? (row.order = index) : true;
      });
    }
  }

  removeField(row: number, fieldIndex: number) {
    console.log('removeField');
    // if (this.newForm !== null) {
    //   this.newForm![row]!.fields.splice(fieldIndex, 1);
    //   this.newForm![row]!.fields.forEach((field, index) => {
    //     field ? (field.fieldOrder = index) : true;
    //   });
    // }
  }

  createDataField() {
    const dialogRef = this.dialog.open(CreateDataFieldComponent, {
      data: { inputTypes: this.inputTypes },
    });
  }

  addField(rowIndex: number) {
    const newField: frontendTypes.formField = {
      dataFieldId: '0',
      fieldOrder: 0,
      required: false,
    };

    if (this.newForm![rowIndex]!.fields === null) {
      this.newForm![rowIndex]!.fields = [newField];
    } else {
      this.newForm![rowIndex]!.fields.push(newField);
    }
  }

  ngAfterviewInit() {}
}
