import { Component, inject, Input, Inject } from '@angular/core';

import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { frontendTypes } from '../../../../assets/types';

@Component({
  selector: 'app-add-contact-field',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSlideToggleModule,
  ],
  templateUrl: './create-datafield.component.html',
  styleUrl: './create-datafield.component.scss',
})
export class CreateDataFieldComponent {
  @Input() titleInput: string | null = 'Create Data Field';

  FIELDTYPES: frontendTypes.inputType[];
  formBuilder = inject(NonNullableFormBuilder);
  createFieldForm = this.formBuilder.group({
    Name: this.formBuilder.control('', Validators.required),
    Label: this.formBuilder.control('', Validators.required),
    Description: this.formBuilder.control(''),
    FieldTypeId: this.formBuilder.control(
      null as number | null,
      Validators.required,
    ),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { inputTypes: frontendTypes.inputType[] },
  ) {
    this.FIELDTYPES = this.data.inputTypes;
  }

  CreateDataField() {
    console.log(
      'todo create data field and await responses',
      this.createFieldForm.value,
    );
  }
}
