import { Injectable, inject } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { AuthService } from '../userservices/auth.service';
import { filter, take, map, firstValueFrom } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class HeadersService {
  auth = inject(AuthService);

  async createHeaders(): Promise<HttpHeaders> {
    let headers = new HttpHeaders();

    try {
      const tokenResult = await firstValueFrom(
        this.auth.checkUser().pipe(
          filter((user) => user !== undefined),
          take(1),
          map((user) => {
            if (user !== null) {
              return user!.getIdTokenResult();
            }
            console.log('No user in headers');
            return '';
          }),
        ),
      );

      let token = '';
      if (tokenResult !== '') {
        token = (await tokenResult).token; // Assuming getIdTokenResult returns a Promise
      }

      if (token) {
        headers = headers.set('Authorization', `Bearer ${token}`);
      }
    } catch (error) {
      console.error('Error creating headers:', error);
      // Handle error or re-throw as needed
    }

    return headers;
  }
}
