import { Component, inject, computed, Input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { TranslateModule } from '@ngx-translate/core';
import { CheckOverflowDirective } from '../../../directives/check-overflow.directive';

import { CloseComponent } from '../buttons/close/close.component';
import { EditComponent } from '../buttons/edit/edit.component';

import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

import { FunctionsService } from '../../../services/functions/functions.service';
import { SettingsService } from '../../../services/settings and config/settings.service';
import { frontendTypes } from '../../../assets/types';
import { ConfigService } from '../../../services/settings and config/config.service';

@Component({
  selector: 'app-dynamic-table',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    CommonModule,
    ConfirmDialogComponent,
    CloseComponent,
    EditComponent,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    CheckOverflowDirective,
  ],
  templateUrl: './dynamic-table.component.html',
  styleUrl: './dynamic-table.component.scss',
})
export class DynamicTableComponent {
  functions = inject(FunctionsService);
  settings = inject(SettingsService);
  config = inject(ConfigService);

  _addButton = signal(false);
  _editButton = signal(false);
  _removeButton = signal(false);
  _dataColumns = signal<string[] | null>(null);
  _systemTable = signal(false);

  addFunction = () => {
    console.log('no add function defined');
  };
  editFunction = (id: string) => {
    console.log('no edit function defined');
  };
  removeFunction = (id: string) => {
    console.log('no remove function defined');
  };

  @Input()
  set tableFunctions(
    input: [
      () => void | null,
      (id: string) => void | null,
      (id: string) => void | null,
    ],
  ) {
    if (input.length == 3) {
      input[0]
        ? (this.addFunction = input[0])
        : () => {
            console.log('no add function defined');
          };
      input[1]
        ? (this.editFunction = input[1])
        : (id: string) => {
            console.log('no edit function defined');
          };
      input[2]
        ? (this.removeFunction = input[2])
        : (id: string) => {
            console.log('no remove function defined');
          };
    }
  }

  @Input()
  set buttonSettings(input: [boolean, boolean, boolean] | null) {
    if (input) {
      this._addButton.set(input[0]);
      this._editButton.set(input[1]);
      this._removeButton.set(input[2]);
    }
  }

  @Input()
  set systemTable(input: boolean | null) {
    if (input) {
      this._systemTable.set(input);
    }
  }

  @Input()
  set dataColumnsInput(input: string[] | null) {
    if (input) {
      this._dataColumns.set(input);
    }
  }

  @Input()
  set tableInput(input: frontendTypes.Data[] | null) {
    if (input) {
      this.dataSource.data = input;
    }
  }
  tableSettings = computed(() => {
    const columns = this._dataColumns() ? this._dataColumns()!.slice() : []; // Create a copy of the array!!

    if (this._editButton()) {
      columns.unshift('editColumn');
    }
    if (this._removeButton()) {
      columns.unshift('deleteColumn');
    }

    if (this._addButton() && !this._removeButton() && !this._editButton()) {
      columns.unshift('editColumn');
    }

    return {
      dataColumns: this._dataColumns(),
      displayedColumns: columns,
      editButton: this._editButton(),
      addButton: this._addButton(),
      removeButton: this._removeButton(),
    };
  });

  dataSource = new MatTableDataSource<frontendTypes.Data>();

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue;
  }

  addRow() {
    this.addFunction();
  }

  editRow(id: string) {
    this.editFunction(id);
  }

  removeRow(id: string) {
    this.removeFunction(id);
  }
}
