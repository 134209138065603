<div class="createFieldContainer">
  <form [formGroup]="createFieldForm">
    <h2 mat-dialog-title>{{ titleInput }}</h2>
    <mat-dialog-content>
      <div class="formFieldRow">
        <mat-form-field appearance="fill">
          <input
            matInput
            type="text"
            formControlName="Name"
            placeholder="Field Name"
        /></mat-form-field>
        <mat-form-field appearance="fill">
          <input
            matInput
            type="text"
            formControlName="Label"
            placeholder="Field Label"
        /></mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Choose a Field Type</mat-label>
          <mat-select formControlName="FieldTypeId">
            @for(type of FIELDTYPES; track type; ) {
            <mat-option [value]="type.inputTypeId">{{
              type.inputTypeName
            }}</mat-option>
            }
          </mat-select></mat-form-field
        >
      </div>
      <div class="formFieldRow">
        <mat-form-field appearance="fill">
          <input
            matInput
            type="text"
            formControlName="Description"
            placeholder="Description"
        /></mat-form-field>
        <mat-dialog-actions align="end">
          <button
            mat-button
            type="button"
            [disabled]="!createFieldForm.valid"
            (click)="CreateDataField()"
          >
            Submit
          </button>

          <button mat-button type="button" [mat-dialog-close]="true">
            Close
          </button>
        </mat-dialog-actions>
      </div>

      <div class="errorcontainer">
        @if(createFieldForm.invalid){
        <span>Invalid input</span>
        } @else {<span class="spacer"> </span>}
      </div>
    </mat-dialog-content>
  </form>
</div>
