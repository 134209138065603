import { Routes } from '@angular/router';
import { AuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { LoginGuard } from '../services/guards/login.guard';
import { isAuthorizedGuard } from '../services/guards/authorization.guard';

import { SignInComponent } from '../components/ui-components/fireBase/signin/signin.component';
import { CheckmailComponent } from '../components/ui-components/fireBase/checkmail/checkmail/checkmail.component';
import { RedirectComponent } from '../components/redirect/redirect.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { ContactsComponent } from '../components/contacts/contacts.component';
import { AdminComponent } from '../components/admin/admin.component';
import { DatafieldsComponent } from '../components/admin/datafields/datafields.component';
// import { PageNotFoundComponent } from '../components/page-not-found/page-not-found.component';

// AuthGuard redirect configuration
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'redirect',
    pathMatch: 'full',
  },
  {
    path: 'redirect',
    component: RedirectComponent,
  },
  {
    path: 'login',
    component: SignInComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'checkmail',
    component: CheckmailComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      {
        path: 'admin',
        component: AdminComponent,
        canActivate: [isAuthorizedGuard],
        data: { roles: ['admin'] },
        children: [
          { path: 'users', component: ContactsComponent },
          { path: 'datafields', component: DatafieldsComponent },
          { path: 'forms', component: ContactsComponent },
        ],
      },
      {
        path: 'registrations',
        component: ContactsComponent,
        canActivate: [isAuthorizedGuard],
        data: { roles: ['admin'] },
      },
      {
        path: 'clients',
        component: ContactsComponent,
        canActivate: [isAuthorizedGuard],
        data: { roles: ['admin'] },
      },
      {
        path: 'projects',
        component: ContactsComponent,
        canActivate: [isAuthorizedGuard],
        data: { roles: ['admin'] },
      },
      {
        path: 'timesheet',
        component: ContactsComponent,
        canActivate: [isAuthorizedGuard],
        data: { roles: ['admin'] },
      },
      {
        path: 'contacts',
        component: ContactsComponent,
        canActivate: [isAuthorizedGuard],
        data: { roles: ['admin'] },
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];
