import { SettingsService } from '../../services/settings and config/settings.service';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterOutlet, RouterLink, Router } from '@angular/router';
import { AuthService } from '../../services/userservices/auth.service';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatRadioModule } from '@angular/material/radio';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { UserService } from '../../services/userservices/user.service';
import { FunctionsService } from '../../services/functions/functions.service';
import { LangselectorComponent } from '../ui-components/langselector/langselector.component';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    RouterOutlet,
    RouterLink,
    MatRadioModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
    LangselectorComponent,
  ],

  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  translate = inject(TranslateService);
  authService = inject(AuthService);
  userService = inject(UserService);
  functions = inject(FunctionsService);
  settingsService = inject(SettingsService);
  router = inject(Router);
  currentUrl = this.functions._currentUrl;

  menuButtonLinks = [
    { url: '/dashboard/registrations', label: 'Registrations' },
    { url: '/dashboard/timesheet', label: 'Time Sheet' },
    { url: '/dashboard/clients', label: 'Clients' },
    { url: '/dashboard/projects', label: 'Projects' },
    { url: '/dashboard/contacts', label: 'Contacts' },
    { url: '/dashboard/admin', label: 'Admin' },
  ];

  themeSelected = this.userService.themePreference;

  changeTheme(theme: 'light' | 'dark') {
    this.userService.updateUser({ Theme: theme });
  }
}
