<ng-container *ngIf="formGroupInitialized; else noForm">
  <form class="input-form" [formGroup]="parentForm">
    <ng-container *ngFor="let row of formInput">
      <div>
        <ng-container *ngFor="let inputField of row.fields">
          <ng-container *ngIf="inputField">
            <!-- Store getDataField result in a local variable -->
            <ng-container *ngIf="functions.getDataField(inputField.dataFieldId) as dataField">
              <mat-form-field *ngIf="dataField.dataType === 'string'">
                <mat-label>{{ dataField.label }}</mat-label>
                <input
                  matInput
                  [formControlName]="inputField.dataFieldId.toString()"
                  autocomplete="off"
                />
              </mat-form-field>

              <mat-form-field *ngIf="dataField.dataType === 'email'">
                <mat-label>{{ dataField.label }}</mat-label>
                <input
                  matInput
                  [formControlName]="inputField.dataFieldId.toString()"
                  autocomplete="off"
                />
              </mat-form-field>

              <mat-form-field
                class="form-input"
                *ngIf="dataField.dataType === 'date'"
                appearance="fill"
              >
                <mat-label>Choose a date</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker"
                  autocomplete="off"
                  [formControlName]="inputField.dataFieldId.toString()"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>

              <ng-container *ngIf="dataField.dataType === 'boolean'">
                <mat-label style="margin-left: 50px">
                  {{ dataField.label }}
                </mat-label>
                <mat-slide-toggle
                  [formControlName]="inputField.dataFieldId.toString()"
                  color="primary"
                 
                ></mat-slide-toggle>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </form>
</ng-container>

<ng-template #noForm>
  <h3>{{ 'INPUTFORM.' + initMessage | translate }}</h3>
</ng-template>
